import React from 'react';

import { collage, imageContainer, image as imageClass } from './collage.module.scss';
import { IMedia } from '../../models/media.model';
import relations from '../../config/relations';
import { getAllMediaWithRelation } from '../../utils/get-media-with-relation';

import BaseRatioImage from './base-ratio-image';
import ImageWithCaption from './image-with-caption';

interface ICollageProps {
    className?: string;
    media: IMedia[];
    collageCaption?: string;
}

const requiredImagesAmount = 3;

const Collage: React.FC<ICollageProps> = ({ className = '', media, collageCaption }) => {
    const collageImages = getAllMediaWithRelation(media, relations.collage);

    if (collageImages.length < requiredImagesAmount) return null;

    return (
        <div className={`${collage} ${className}`}>
            {collageImages.map((image, id) => {
                const isLastImage = id === collageImages.length - 1;
                const imageProps = {
                    url: image.url,
                    className: imageContainer,
                    imgClass: imageClass,
                };

                return isLastImage ? (
                    <ImageWithCaption
                        key={`collage-list-${id}`}
                        caption={collageCaption}
                        {...imageProps}
                    />
                ) : (
                    <BaseRatioImage key={`collage-list-${id}`} {...imageProps} />
                );
            })}
        </div>
    );
};

export default Collage;

import React from 'react';

import { description } from './image-with-caption.module.scss';
import { IGatsbyImageData } from 'gatsby-plugin-image';

import BaseRatioImage from './base-ratio-image';
import Heading from './heading';

interface IImageWithCaptionProps {
    className?: string;
    imgClass?: string;
    image?: IGatsbyImageData;
    alt?: string;
    url?: string;
    caption?: string;
}

const ImageWithCaption: React.FC<IImageWithCaptionProps> = ({
    className = '',
    imgClass = '',
    image,
    alt,
    url,
    caption,
}) => {
    if (!url && !image) return null;

    return (
        <div className={className}>
            <BaseRatioImage image={image} url={url} alt={alt} imgClass={imgClass} />
            {caption && (
                <Heading numberOfStyle={8} isRegular={true} className={description}>
                    {caption}
                </Heading>
            )}
        </div>
    );
};

export default ImageWithCaption;

import React from 'react';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    EmailShareButton,
} from 'react-share';
import { useLocation } from '@reach/router';

import * as styles from './share-buttons.module.scss';
import FacebookIcon from '../../assets/images/svg/facebook.svg';
import LinkedinIcon from '../../assets/images/svg/linkedin.svg';
import TwitterIcon from '../../assets/images/svg/twitter.svg';
import EnvelopeIcon from '../../assets/images/svg/envelope.svg';
import useTranslations from '../../hooks/use-translations';

import Heading from '../atoms/heading';

type TSocialMedia = 'twitter' | 'facebook' | 'linkedin' | 'email';

export interface IShareButtonsProps {
    href?: string;
    className?: string;
}

export default function ShareButtons({ href, className }: IShareButtonsProps) {
    const t = useTranslations('ShareButtons');
    const { href: defaultHref } = useLocation();
    const socialMedia = {
        twitter: {
            button: TwitterShareButton,
            icon: TwitterIcon,
        },
        facebook: {
            button: FacebookShareButton,
            icon: FacebookIcon,
        },
        linkedin: {
            button: LinkedinShareButton,
            icon: LinkedinIcon,
        },
        email: {
            button: EmailShareButton,
            icon: EnvelopeIcon,
        },
    };
    const socialMediaKeys = Object.keys(socialMedia) as TSocialMedia[];

    return (
        <div className={`${styles.shareButtons} ${className}`}>
            <Heading numberOfStyle={8} isRegular={true}>
                {t.heading}
            </Heading>

            {socialMediaKeys.map((key: TSocialMedia, index) => {
                const Button = socialMedia[key].button;
                const Icon = socialMedia[key].icon;

                return (
                    <Button
                        key={`share-button-${index}`}
                        url={href ? href : defaultHref}
                        aria-label={t[key]}
                        className={`${styles.button} ${styles[key]}`}
                    >
                        <Icon />
                    </Button>
                );
            })}
        </div>
    );
}

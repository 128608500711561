import React from 'react';
import { graphql } from 'gatsby';

import {
    grid,
    container,
    innerContainer,
    image,
    headingContainer,
    heading,
    share,
    subheading,
    markdownContainer,
    markdown,
    related,
} from './single-news.module.scss';
import { IQueryAllResult } from '../models/query-all-result.model';
import { INews } from '../models/news.model';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import Pagination from '../components/molecules/pagination';
import NewsMetadata from '../components/atoms/news-metadata';
import Heading from '../components/atoms/heading';
import Content from '../components/atoms/content';
import Markdown from '../components/hoc/markdown';
import Collage from '../components/atoms/collage';
import ShareButtons from '../components/molecules/share-buttons';
import RelatedNews from '../components/molecules/related-news';
import NewsImage from '../components/molecules/news-image';

interface INewsQueryResults {
    allNews: IQueryAllResult<INews>;
    currentNews: INews;
    prevNews: INews;
    nextNews: INews;
}

interface INewsProps {
    data: INewsQueryResults;
}

const SingleNews: React.FC<INewsProps> = ({ data }) => {
    const news = getNodes(data.allNews);
    const currentNews = data.currentNews || {};
    const prevNews = data.prevNews || {};
    const nextNews = data.nextNews || {};
    const relatedNews = news.filter((singleNews) =>
        currentNews.relatedNews.includes(singleNews.newsId)
    );
    const {
        publishedAt,
        media,
        title,
        lead,
        category,
        content,
        collageCaption,
        isCovid,
        isUkraine,
    } = currentNews;

    return (
        <MainLayout>
            <div className={grid}>
                <div className={container}>
                    <div className={innerContainer}>
                        {media && (
                            <NewsImage
                                media={media}
                                imgClass={image}
                                isCovid={isCovid}
                                isUkraine={isUkraine}
                            />
                        )}
                        {publishedAt && <NewsMetadata date={publishedAt} category={category} />}
                        <div className={headingContainer}>
                            <Heading numberOfStyle={3} className={heading}>
                                {title}
                            </Heading>
                            <ShareButtons className={share} />
                        </div>
                        <div className={markdownContainer}>
                            {lead && (
                                <Content className={subheading} numberOfStyle={1}>
                                    {lead}
                                </Content>
                            )}
                            <Markdown
                                className={markdown}
                                components={{
                                    shortcode: () => (
                                        <Collage media={media} collageCaption={collageCaption} />
                                    ),
                                }}
                            >
                                {content}
                            </Markdown>
                        </div>
                    </div>
                    <Pagination
                        as={'post'}
                        prev={{ pathname: prevNews.pathname }}
                        next={{ pathname: nextNews.pathname }}
                    />
                    {relatedNews.length > 0 && (
                        <RelatedNews className={related} news={relatedNews} />
                    )}
                </div>
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($newsId: Int, $prevId: Int, $nextId: Int) {
        allNews(sort: { order: DESC, fields: publishedAt }) {
            edges {
                node {
                    ...newsFields
                }
            }
        }

        currentNews: news(newsId: { eq: $newsId }) {
            ...newsFields
        }

        prevNews: news(newsId: { eq: $prevId }) {
            ...newsFields
        }

        nextNews: news(newsId: { eq: $nextId }) {
            ...newsFields
        }
    }
`;

export default SingleNews;

// extracted by mini-css-extract-plugin
export var container = "single-news-module--container--f5da0";
export var grid = "single-news-module--grid--bfd8b";
export var heading = "single-news-module--heading--a6fe8";
export var headingContainer = "single-news-module--heading-container--ad39d";
export var image = "single-news-module--image--ed5dd";
export var innerContainer = "single-news-module--inner-container--b4cf3";
export var markdown = "single-news-module--markdown--7d169";
export var markdownContainer = "single-news-module--markdown-container--7710d";
export var related = "single-news-module--related--63b2e";
export var share = "single-news-module--share--d0cd0";
export var subheading = "single-news-module--subheading--e9b9e";